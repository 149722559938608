import React, { useEffect } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Header from '../components/header'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import Footer from '../components/footer'

export default function ThankYou({ data }) {
	const page = data.datoCmsTapestryLandingPage;


	useEffect(() => {
		 localStorage.setItem('submitted', true)
		if (!window.location.hash) {
			window.location = window.location + '#loaded';
			window.location.reload();
		}
	}, [])

	const contactInfo = { email: page.email, insta: page.instagram, facebook: page.facebook }
	return (
		<Layout>
			<SEO title='Thanks' />
			<Header />

			<div className='flex  p40 min-h-100vh '>
				<div className="max-400 ma text-center">
					<h1 className='mb40'>Thank You for registering</h1>
					<p className="m0 mb40">
						Thank you for registering to know more about Tapestry. You will receive your exclusive access to our Tapestry brochure shortly and one of our sales agents will be in touch with you soon. </p>

					<div>
						<Link style={{ paddingTop: '12px', paddingBottom: '12px' }} to='/' className="button enquire--button white  m0">Head Back to Home</Link>
					</div>
				</div>
			</div>
			<Footer contactInfo={contactInfo} info={page} />

		</Layout>

	)
}
export const query = graphql`
	query ThankYouPage{
		datoCmsTapestryLandingPage {
			ctaImage{
				gatsbyImageData
			}
			mobileIntroductionImage{
				gatsbyImageData
			}
			designQuoteAttribution
			designQuote
			carouselImages {
				gatsbyImageData
				url
			}
			gallery {
     			gatsbyImageData
   			 }
			designImage {
				gatsbyImageData
			}
			introductionText
			introductionImage {
				gatsbyImageData
			}   
			magazineName
            magazineImage {
                gatsbyImageData
            }
			designSubtitle
			splitSectionParagraph
			splitSectionImage {
				gatsbyImageData
			}
			splitSectionSubtitle
			address
			email
			instagram
			facebook 
			people {
				email
				name
				phone
			}
		}
	 
}
`
